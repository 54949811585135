import { AssetType, Prisma } from "@prisma/client";
import { rate as getRate, PaymentDueTime, pmt } from "financial";
import {
  FIXED_INTEREST_RATE_CEL,
  REDUCING_INTEREST_RATE_HEL,
} from "./constants";

/**
 * Get the equivalent reducing interest rate for a flat interest rate.
 *
 * @param rate Flat interest rate
 * @param nper Number of payment periods
 * @param pv Principal value
 * @param fv Final value (default 0)
 * @param when Whether the payment is made at the beginning or end of the period
 * @returns The reducing interest rate
 */
export const flatToReducing = (
  rate: number,
  nper: number,
  pv: number,
  fv = 0,
  when = PaymentDueTime.End
): number => {
  const principal = pv - fv;
  const interest = principal * rate * nper;
  const total = principal + interest;
  const payment = total / nper;
  const reducing = getRate(nper, -payment, pv, fv, when);
  return reducing;
};

/**
 * Get the equivalent flat interest rate for a reducing interest rate.
 *
 * @param rate Reducing interest rate
 * @param nper Number of payment periods
 * @param pv Principal value
 * @param fv Final value (default 0)
 * @param when Whether the payment is made at the beginning or end of the period
 * @returns The flat interest rate
 */
export const reducingToFlat = (
  rate: number,
  nper: number,
  pv: number,
  fv = 0,
  when = PaymentDueTime.End
): number => {
  const payment = -pmt(rate, nper, pv, fv, when);
  const total = payment * nper;
  const principal = pv - fv;
  const interest = total - principal;
  const flat = interest / (principal * nper);
  return flat;
};

/** Calculate the principal for a given payment. Inverse of {@link pmt}.
 * @param rate Reducing interest rate
 * @param nper Number of payment periods
 * @param pmt Payment amount
 * @param when Whether the payment is made at the beginning or end of the period
 * @returns The principal value
 */

export const getPrincipal = (
  rate: number,
  nper: number,
  pmt: number,
  when = PaymentDueTime.End
): number => {
  const isRateZero = rate === 0;
  const temp = (1 + rate) ** nper;
  const whenMult = when === PaymentDueTime.Begin ? 1 : 0;
  const maskedRate = isRateZero ? 1 : rate;
  const fact = isRateZero
    ? nper
    : ((1 + maskedRate * whenMult) * (temp - 1)) / maskedRate;

  // pmt = -(fv + pv * temp) / fact
  return (pmt * fact) / temp;
};

export const getInterestRateBasedOnAssetType = (
  assetType: AssetType,
  tenure: number,
  principal: number
) => {
  return assetType === "HOME"
    ? REDUCING_INTEREST_RATE_HEL
    : new Prisma.Decimal(
        flatToReducing(FIXED_INTEREST_RATE_CEL.toNumber(), tenure, principal)
      );
};
