import { Prisma } from "@prisma/client";
import { ipmt, pmt, ppmt } from "financial";
import {
  getCapacity,
  getIncome,
} from "../../services/home-equity/calculations";
import { flatToReducing, getPrincipal } from "../loans/calculations";
import {
  BORROWER_MAX_AGE,
  FIXED_INTEREST_RATE_CEL,
  LOAN_TERMS,
  MAX_LOAN_VALUE_RATIO_CAR,
} from "../loans/constants";
import { Borrower, Payment } from "../loans/types";
import {
  CarCheckData,
  CarLoanParameters,
  IneligibleReason,
  LegacyCarLoanParameters,
} from "./types";

// Car loans have no max DBR - max principal is always LTV * value
export const getMaxPrincipal = (vehicleValue: Prisma.Decimal): Prisma.Decimal =>
  vehicleValue.times(MAX_LOAN_VALUE_RATIO_CAR);

export type CarLoanData = Pick<CarCheckData, "age" | "vehicleValue">;

export const getCarLoanParameters = ({
  borrower,
  selectedPrincipal,
}: {
  borrower: Borrower;
  selectedPrincipal: Prisma.Decimal;
}): CarLoanParameters[] => {
  const capacity = getCapacity(borrower);
  const effectiveIncome = getIncome(borrower);
  const maxAge = BORROWER_MAX_AGE;

  const terms = LOAN_TERMS.filter((term) =>
    borrower.age.plus(new Prisma.Decimal(term).dividedBy(12)).lessThan(maxAge)
  );

  const parameters = terms.map((term) => {
    let principal = selectedPrincipal.toNumber();

    // convert flat rate to reducing rate
    const interestRate = flatToReducing(
      FIXED_INTEREST_RATE_CEL.toNumber(),
      term,
      principal
    );

    const nper = term;
    let payment = pmt(interestRate, nper, principal);

    // Ensure installment payment does not exceed debt capacity
    if (capacity.lessThan(-payment)) {
      principal = getPrincipal(interestRate, nper, capacity.toNumber());

      payment = pmt(interestRate, term, principal);
    }

    const decimalPayment = new Prisma.Decimal(payment).abs();
    const total = decimalPayment.times(term);
    const interest = total.minus(principal);

    const schedule = Array.from(Array(term)).map(
      (_, i): Payment => ({
        period: i + 1,
        interest: new Prisma.Decimal(
          ipmt(interestRate, i + 1, nper, principal)
        ),
        principal: new Prisma.Decimal(
          ppmt(interestRate, i + 1, nper, principal)
        ),
      })
    );

    return {
      capacity,
      effectiveIncome,
      interest,
      interestRate,
      payment: decimalPayment,
      principal: new Prisma.Decimal(principal),
      schedule,
      term,
      total,
    };
  });

  return parameters;
};

export const getLegacyCarLoanParameters = (
  data: CarLoanData,
  selectedPrincipal?: number
): LegacyCarLoanParameters[] => {
  const maxPrincipal = getMaxPrincipal(data.vehicleValue);

  const parameters = LOAN_TERMS.map((term): LegacyCarLoanParameters => {
    const principal = selectedPrincipal ?? maxPrincipal.toNumber();
    const interestRate = flatToReducing(
      FIXED_INTEREST_RATE_CEL.toNumber(),
      term,
      principal
    );
    const payment = pmt(interestRate, term, principal);

    const decimalPayment = new Prisma.Decimal(payment).abs();
    const total = decimalPayment.times(term);
    const interest = total.minus(principal);

    const schedule = Array.from(Array(term as number)).map(
      (_, i): Payment => ({
        period: i + 1,
        interest: new Prisma.Decimal(
          ipmt(interestRate, i + 1, term, principal)
        ),
        principal: new Prisma.Decimal(
          ppmt(interestRate, i + 1, term, principal)
        ),
      })
    );

    return {
      interest,
      interestRate: new Prisma.Decimal(interestRate),
      payment: decimalPayment,
      principal: new Prisma.Decimal(principal),
      schedule,
      term: new Prisma.Decimal(term),
      total,
      vehicleValue: data.vehicleValue,
    };
  });

  return parameters;
};

const defaultBorrower: Borrower = {
  age: new Prisma.Decimal(21),
  debt: new Prisma.Decimal(0),
  additionalIncome: new Prisma.Decimal(0),
  income: new Prisma.Decimal(0),
};

/**
 * Get loan parameters the given income and vehicle value.
 *
 * Assumes a fully-owned, new vehicle for a debt-free person within the age limit.
 */
export const getAnonymousCarLoanParameters = (
  income: Prisma.Decimal,
  vehicleValue: Prisma.Decimal
) => {
  const borrower: Borrower = { ...defaultBorrower, income };
  const selectedPrincipal = getMaxPrincipal(vehicleValue);

  return getCarLoanParameters({ borrower, selectedPrincipal });
};

export const getCarEligibility = (
  data: CarCheckData
): {
  parameters: LegacyCarLoanParameters[];
  ineligibleReasons: IneligibleReason[];
} => {
  const ineligibleReasons: IneligibleReason[] = [];

  if (data.vehicleOwnership !== "owned") {
    ineligibleReasons.push("ownership");
  }

  const parameters = getLegacyCarLoanParameters(data);

  return { parameters, ineligibleReasons };
};
