/**
 * map of developers and their compounds
 * stored in lowercase
 */
export const developerCompounds = {
  "emaar-misr": ["uptown-cairo", "marassi", "mivida", "cairo-gate"],
  "hassan-allam": [
    "seasons-residence",
    "park-view",
    "sheraton-springs",
    "little-venice-sokhna",
    "hap-town",
    "swan-lake-residence",
    "swan-lake",
  ],
  "hyde-park-development": ["hyde-park-residence", "coast-82"],
  "la-sirena-group": [
    "la-sirena-palm-beach",
    "la-sirena-mini-egypt",
    "la-sirena-moussa-coast",
    "la-sirena-canaan-capital",
  ],
  "mountain-view": [
    "mountain-view-1",
    "icity",
    "mountain-view-2",
    "katameya-executive-residence",
    "hyde-park",
    "executive-residence",
    "october-park",
    "diplomats-ras-elhekma",
    "ras-el-hekma",
    "sokhna-1",
    "sokhna-2",
  ],
  "nasr-city-housing-and-development-mnhd": ["taj-city"],
  "palm-hills-developments-phd": [
    "palm-hills-golf-extension",
    "palm-hills-october",
    "palm-hills-katameya",
    "plam-hills-katameya-extension",
    "the-village",
    "village-gardens-katameya",
    "village-gate",
    "hacienda-bay",
    "hacienda-white",
    "palm-parks",
    "casa",
    "palm-hills-new-cairo",
    "village-avenue",
  ],
  sodic: [
    "sodic-west-galleria-beverly",
    "katameya-plaza",
    "october-plaza",
    "one-16",
  ],
  "sodic-soreal": ["east-town", "villette-sky-condos"],
  "talaat-mostafa-group-alexandria-for-real-estate-development": [
    "madinaty",
    "alrabwa",
    "mayfair",
    "virginia-beach",
    "marsa-alam",
    "san-stefano-residential",
    "al-rehab",
  ],
  "talaat-mostafa-group-arabia-for-real-estate-development": ["celia"],
  "tatweer-misr": ["il-monte-galala-ain-el-sokhna", "fouka-bay", "bloomfields"],
  "tiba-touristic-real-estate-development-la-vista-developments": [
    "la-vista-2",
    "la-vista-3",
    "la-vista-4",
    "la-vista-5",
    "la-vista-6",
    "la-vista-north-coast",
    "la-vista-marsa-alam",
    "elpatio-el-zahraa",
    "la-vista-1",
    "el-patio-1",
    "el-patio-2",
    "el-patio-3",
    "el-patio-4",
    "el-patio-5",
    "el-patio-6",
  ],
  "wadi-degla-real-estate-development": [
    "murano",
    "neopolis",
    "promenade",
    "tijan",
    "canal-residence",
    "river-walk",
    "marina-wadi-degla-promenade",
    "promenade",
    "pyramids-walk",
    "blumar-el-dome",
    "blumar-sidi-abdel-rahman",
    "blumar-hurghada",
    "bulmar-elsokhna",
  ],
  "al-burouj-development": ["heaven-hills", "sixty-iconic-tower"],
  "al-marasem-development": [
    "fifth-square",
    "moon-residences",
    "lake-residence",
  ],
  "al-ahly-for-real-estate-development-sabbour": [
    "gaia",
    "the-city-of-odyssia",
    "tha-ridge",
    "alaire",
    "rare",
    "keeva",
  ],
  "city-edge-developments": [
    "al-maqsad-residences",
    "down-town",
    "north-edge",
    "the-gate-towers",
    "al-maqsad",
    "zahya",
  ],
  dorra: ["al-mostakbal", "address-east", "village-west"],
  "iwan-for-investment-development": ["jeera-compound", "jewar", "jedar"],
};

export type Developer = keyof typeof developerCompounds;
export type Compound = (typeof developerCompounds)[Developer][number];
export type DeveloperCompounds = Record<Developer, readonly Compound[]>;
export const developerCompoundOptions: DeveloperCompounds = developerCompounds;
export const developerOptions = Object.keys(developerCompounds) as Developer[];

// Types for /data-model/locales/en-US/developer-compounds.json
export interface CompoundsInJson {
  id: number;
  label: string;
}

export interface DevelopersInJson {
  id: number;
  label: string;
  compounds: Record<string, CompoundsInJson>;
}

export interface DeveloperCompoundsJson {
  "developer-compounds": Record<string, DevelopersInJson>;
}
