import { Prisma } from "@prisma/client";

/** The oldest a borrower may be while carrying loan debt */
export const BORROWER_MAX_AGE = 70;

/** Default monthly interest rate for a mortgage */
export const REDUCING_INTEREST_RATE_HEL = new Prisma.Decimal(0.33).dividedBy(
  12
);

/** Default monthly interest rate for a car loan */
export const FIXED_INTEREST_RATE_CEL = new Prisma.Decimal(0.25).dividedBy(12);

/**
 * Default loan terms in years
 * @deprecated Use {@link LOAN_TERMS} instead
 */
export const DEFAULT_LOAN_TERM_ANNUAL = [5, 7, 10];

/**
 * Default loan terms in months
 * @deprecated Use {@link LOAN_TERMS} instead
 */
export const DEFAULT_LOAN_TERMS = DEFAULT_LOAN_TERM_ANNUAL.map((term) =>
  new Prisma.Decimal(term).times(12)
);

/**
 * The minimum loan amount for a HEL
 * @deprecated Use {@link MINIMUM_LOAN_AMOUNT} instead
 */
export const MINIMUM_HEL_LOAN_AMOUNT = new Prisma.Decimal(500000);

/**
 * Default loan terms in Months for car loan
 * @deprecated Use {@link LOAN_TERMS} instead
 */

export const DEFAULT_CAR_LOAN_TERMS = [12, 24, 36, 48, 60, 72, 84] as const;

/**
 * The minimum loan amount for a CEL
 * @deprecated Use {@link MINIMUM_LOAN_AMOUNT} instead
 */
export const MINIMUM_CEL_LOAN_AMOUNT = new Prisma.Decimal(100000);

/*
 * The minimum loan amount for a HEL & CEL
 */
export const MINIMUM_LOAN_AMOUNT = new Prisma.Decimal(150000);

/**
 * Loan Terms for HEL & CEL
 */
export const LOAN_TERMS = [6, 12, 18, 24, 30, 36, 42, 48, 54, 60] as const;

/**
 * Minimum income in EGP
 *
 * This is the minimum income that a borrower must have in order to qualify for
 * a loan of minimum amount: {@link MINIMUM_LOAN_AMOUNT}
 **/
export const MINIMUM_INCOME = new Prisma.Decimal(1000);

/**
 * The highest proportion of a borrower's income that may constitute debt
 * repayment
 **/
export const MAX_INCOME_DEBT_RATIO = 0.5;

/**
 * The highest proportion of a borrower's additional income that considered for
 * borrowing capacity
 **/
export const MAX_ADDITIONAL_INCOME_RATIO = 0.5;

/**
 * Percentage of credit limit considered to be debt, regardless of utilization
 **/
export const CREDIT_LIMIT_DEBT_FACTOR = 0.05;

/** The proportion of property value that may be borrowed */
export const MAX_LOAN_VALUE_RATIO = 0.8;

/** The proportion of car value that may be borrowed */
export const MAX_LOAN_VALUE_RATIO_CAR = 0.65;

/**
 * The minimum value of a property that may be used as collateral
 */
export const MIN_PROPERTY_VALUE = new Prisma.Decimal(100000);

/**
 * The minimum value of a car that may be used as collateral
 */
export const MIN_CAR_VALUE = new Prisma.Decimal(100000);

/**
 * The maximum value of a property that may be used as collateral
 */
export const MAX_PROPERTY_VALUE = new Prisma.Decimal(200000000);

/**
 * The maximum value of a car that may be used as collateral
 */
export const MAX_CAR_VALUE = new Prisma.Decimal(30000000);
